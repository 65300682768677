import { createMuiTheme } from '@material-ui/core/styles';

// A custom theme for this app
const theme = createMuiTheme({
    palette: {
        type: 'dark',
        primary: {
            main: '#2A2D32',//'#97233F',
        },
        secondary: {
            main: '#D4D4D8',
        },
        error: {
            main: '#2A2D32',//'#97233F',
        },
        background: {
            default: '#97233F',//'#2A2D32',
            paper: '#303338',
        },
        maroon: {
            main: '#97233F',
        },
        lightestGray: {
            main: '#D4D4D8',
        },
        lighterGray: {
            main: '#D5D5D9',
        },
        lightGray: {
            main: '#676767',
        },
        gray: {
            main: '#303338'
        },
        darkGray: {
            main: '#2A2D32',
        },
    },
    typography: {
        subtitle1: {
            fontSize: 28,
            color: '#D4D4D8',
            lineHeight: 1,
        },
        subtitle2: {
            fontSize: 20,
            color: '#D4D4D8',
            fontWeight: 400,
        },
    },
    input: {
        background: "#fff",
        textAlign: 'inherit',
        '&$focused': {
            background: "#fff",
        },
    },
    overrides: {
        MuiPaper: {
            rounded: {
                borderRadius: '25px',
            },
        },
        MuiPopover: {
            paper: {
                borderRadius: '4px !important',
            },
        },

        MuiInputBase: {
            input: {
                textAlign: 'inherit',
            }
        },
        MuiFormLabel: {
            root: {
                // this is so that the background color of the label isn't partially red and partially pink
                backgroundColor: '#AF5B70',
                padding: '3px 7px', // 7px instead of 6px to account for minor rendering differences
                marginLeft: '-6px',
                marginTop: '-3px',
                borderRadius: '3px',

                color: 'white',
                '&$focused': {
                    color: 'white',
                },
            },
            focused: {},
        },
        MuiFilledInput: {
            root: {
                backgroundColor: '#AF5B70',
                '&:hover': {
                    backgroundColor: '#AF5B70',
                },
                '&$focused': {
                    backgroundColor: '#AF5B70',
                },
                '&$focused $notchedOutline': {
                    borderColor: 'white',
                },
            },
        },
        MuiOutlinedInput: {
            root: {
                backgroundColor: '#AF5B70', // not using alpha because we're overlaying the label // 'rgba(255, 255, 255, 0.25)',
                '&$focused $notchedOutline': {
                    borderColor: 'white',
                },
            },
            notchedOutline: {
                borderColor: 'white',
            },

            focused: {},
        },
        MuiTableCell: {
            root: {
                borderBottom: '1px solid rgba(255, 255, 255, 0.5)',
            },
        },
    },
});

export default theme;