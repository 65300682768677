import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { shadows } from '@material-ui/system';
import SvgIcon from '@material-ui/core/SvgIcon';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import AccountCircle from '@material-ui/icons/AccountCircle';

import { Form, Field } from 'react-final-form'

import { TextField } from 'mui-rff';

import { useHistory } from "react-router-dom";

import {ReactComponent as WPLogo} from '../images/wear-plate-logo.svg';
import whyUpgrade from '../images/why-upgrade-to-hardox-white.png';
import { apiUrl, baseUrl } from '../dataProvider';

const useStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        flexDirection: "column",
        maxWidth: "1400px",
        alignItems: "center",
        margin: "auto",
    },
    header: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        margin: theme.spacing(4),
        width: "100%",
    },
    wpLogo: {
        alignSelf: "flex-start",
        width: "250px",
        marginBottom: theme.spacing(8),
        marginLeft: theme.spacing(3),
        filter: "drop-shadow(5px 8px 5px #00000096)",/* imitating theme.shadows[8] sort of */
    },
    contentContainer: {
        maxWidth: "800px",
        width: "100%",
        marginTop: theme.spacing(2),
        padding: theme.spacing(2),
    },
    paperContainer: {
        backgroundColor: theme.palette.maroon.main,
        maxWidth: "800px",
        width: "100%",
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
        padding: theme.spacing(4),

        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    buttonContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    padderB: {
        marginBottom: theme.spacing(4),
    }
}));

const onSubmit = async (values, history) => {
    const url = `${apiUrl}/reports/`;
    let data = {
        report_type: values.type,
        created_by: values.email,
    };

    const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        },
        body: JSON.stringify(data)
    });

    const jsonResponse = await response.json();

    console.log(jsonResponse);

    if (jsonResponse.uuid) {
        const location = {
            pathname: '/calculator/'+jsonResponse.uuid,
            state: { report: jsonResponse }
        }

        history.push(location)
    } else {
        alert("There was an unknown error. Please try again later.");
    }
}

const Home = () => {
    const classes = useStyles();
    const history = useHistory();

    return (
        <Box className={classes.container}>
            <Box className={classes.header}>
                <WPLogo className={classes.wpLogo} />
                <img width="800px" src={whyUpgrade} alt="Why Upgrade to Hardox® 500 Tuf?" />
            </Box>
            <Box className={classes.contentContainer}>
                <Typography variant="h5" component="h3" className={classes.padderB}>
                    It’s high time to start saving! Use this upgrade calculator to show your customers how they can extend service life or reduce weight by using Hardox® 500 Tuf. Let’s get started!
                    {/* Use our upgrade calculator to show your customers how Hardox® 500 Tuf will save them money over time by extending service life and reducing freight cost. */}
                </Typography>
            </Box>
            <Paper className={classes.paperContainer} elevation={8}>
                <Typography variant="h3" component="h3" className={classes.padderB}>Enter your email address to begin:</Typography>
                <Form
                    onSubmit={(values) => (onSubmit(values, history))}
                    render={({ handleSubmit, form, submitting, pristine, values }) => (
                        <form onSubmit={handleSubmit}>
                            <div>
                                <Grid container spacing={1} alignItems="flex-end" justify="center" className={classes.padderB}>
                                    <Grid item>
                                        <AccountCircle />
                                    </Grid>
                                    <Grid item>
                                        <TextField label="Email" name="email" variant="outlined" required />
                                    </Grid>
                                </Grid>

                                <ButtonGroup variant="contained" color="primary" size="large" disabled={submitting || pristine}>
                                    <Button onClick={() => {values.type = "wear"; form.submit()}}>EXTEND SERVICE LIFE</Button>
                                    <Button onClick={() => {values.type = "thickness"; form.submit()}}>REDUCE WEIGHT</Button>
                                </ButtonGroup>
                            </div>
                        </form>
                    )}
                />
            </Paper>
        </Box>
    );
}

export default Home;