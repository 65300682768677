import axios from 'axios';
import { stringify } from 'query-string';

let baseUrl;
let apiUrl;

// live
if(window.location.href.toLowerCase().indexOf('specialsteelsnorthamerica') !== -1) { // live
    baseUrl = 'https://get-tuf.specialsteelsnorthamerica.com';
    apiUrl = 'https://huc-api.specialsteelsnorthamerica.com';
} else { // dev
    baseUrl = 'http://ssab-calc.local:3000';
    apiUrl = 'http://api.ssab-calc.local:8080';
}

const apiClient = axios.create({
    baseURL: apiUrl,
    withCredentials: true,
});

const ssabDataProvider = {
    getList: (resource, params) => {
        console.log("getList", resource);
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
            sort: JSON.stringify([field, order]),
            page: page,
            per_page: perPage,
            filter: JSON.stringify(params.filter),
        };
        const url = `/${resource}?${stringify(query)}`;

        return apiClient(url).then(({ data }) => {
            return ({
                data: data.data,
                total: parseInt(data.total),
            })
        });
    },

    getOne: (resource, params) => {
        console.log("getOne", resource);
        return apiClient(`${apiUrl}/${resource}/${params.id}`).then(({ data }) => ({
            data: data,
        }));
    },

    getMany: (resource, params) => {
        console.log("getMany", resource);
        const query = {
            filter: JSON.stringify({ id: params.ids }),
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;
        return apiClient(url).then(({ data }) => {
            return ({
                data: data.data,
            })
        });
    },

    getManyReference: (resource, params) => {
        console.log("getManyReference", resource);
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
            sort: JSON.stringify([field, order]),
            range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
            filter: JSON.stringify({
                ...params.filter,
                [params.target]: params.id,
            }),
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;

        return apiClient(url).then(({ data }) => ({
            data: data.data,
            total: parseInt(data.total),
        }));
    },

    update: (resource, params) => {
        console.log("update", resource);
        return apiClient.put(`${apiUrl}/${resource}/${params.id}`, params.data)
        .then(({ data }) => ({
            data: data,
        }));
    },

    updateMany: (resource, params) => {
        console.log("updateMany", resource);
        const query = {
            filter: JSON.stringify({ id: params.ids}),
        };
        return apiClient.put(`${apiUrl}/${resource}?${stringify(query)}`, params.data)
        .then(({ data }) => ({
            data: data,
        }));
    },

    create: (resource, params) =>
        apiClient(`${apiUrl}/${resource}`, {
            method: 'POST',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({
            data: { ...params.data, id: json.id },
        })),

    delete: (resource, params) => {
        console.log("delete", resource);
        return apiClient(`${apiUrl}/${resource}/${params.id}`, {
            method: 'DELETE',
        }).then(({ data }) => ({
            data: data,
        }));
    },

    deleteMany: (resource, params) => {
        console.log("deleteMany", resource);
        const query = {
            filter: JSON.stringify({ ids: params.ids}),
        };
        return apiClient(`${apiUrl}/${resource}?${stringify(query)}`, {
            method: 'DELETE',
            body: JSON.stringify(params.data),
        }).then(({ data }) => ({
            data: data,
        }));
    },
};

export {
    baseUrl,
    apiUrl,
    apiClient,
};
export default ssabDataProvider;
