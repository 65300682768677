import React from 'react';

import Box from '@material-ui/core/Box';

import Home from './Home';
import Calculator from './Calculator';

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";

const App = () => {
    return (
        <Router>
            <Box width="100vw" margin="auto">
                {/* A <Switch> looks through its children <Route>s and
                    renders the first one that matches the current URL. */}
                <Switch>
                    <Route path="/calculator/:reportId">
                        <Calculator />
                    </Route>
                    <Route path="/">
                        <Home />
                    </Route>
                </Switch>
            </Box>
        </Router>
    );
}

export default App