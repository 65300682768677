import React, { useState, useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import FormHelperText from '@material-ui/core/FormHelperText';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import DeleteIcon from '@material-ui/icons/Delete';
import HomeIcon from '@material-ui/icons/Home';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { Form, Field } from 'react-final-form';
import { TextField as RffTextField } from 'mui-rff';

import NumberFormat from 'react-number-format';

import { useParams, useHistory, useLocation, Link } from "react-router-dom";

import {ReactComponent as WPLogo} from '../images/wear-plate-logo.svg';
import whyUpgrade from '../images/why-upgrade-to-hardox-white.png';
import { apiUrl, baseUrl } from '../dataProvider';

const useStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        flexDirection: "column",
        maxWidth: "1400px",
        alignItems: "center",
        margin: "auto",
    },
    header: {
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
        justifyContent: 'space-between',
        marginTop: theme.spacing(4),
        marginRight: theme.spacing(3),
        marginBottom: theme.spacing(2),
        marginLeft: theme.spacing(3),
        width: "100%",
    },
    whyUpgradeHeader: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: "-80px",
        marginBottom: theme.spacing(4),
        width: "33%",
    },
    instructions: {
        display: "flex",
        flexDirection: "column",
        // alignItems: "center",
        marginBottom: theme.spacing(4),
        // width: "75%",
    },
    wpLogo: {
        alignSelf: "flex-start",
        width: "250px",
        // marginBottom: theme.spacing(4),
        // marginLeft: theme.spacing(3),
        filter: "drop-shadow(5px 8px 5px #00000096)",/* imitating theme.shadows[8] sort of */
    },
    paperContainer: {
        backgroundColor: theme.palette.maroon.main,
        maxWidth: "1200px",
        width: "100%",
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
        padding: theme.spacing(4),

        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    buttonContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    tableContainer: {
        maxWidth: "1400px",
        minWidth: "800px",
        margin: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    upgradeContainer: {
        backgroundColor: theme.palette.maroon.main,
        borderRadius: "8px",
        boxShadow: theme.shadows[8],
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        paddingLeft: theme.spacing(8),
        paddingRight: theme.spacing(8),
        marginBottom: theme.spacing(4),
        width: '100%',

        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    padderB: {
        paddingBottom: theme.spacing(4),
    },
    padderBSm: {
        paddingBottom: theme.spacing(2),
    },
    padderL: {
        paddingLeft: theme.spacing(4),
    },
    upgradeText: {
        fontSize: 25,
    },
    upgradeTextLarge: {
        fontSize: 35,
        lineHeight: 1,
    },
    upgradeTextDescription: {
        color: theme.palette.darkGray.main,
        fontSize: 20,
    },
    upgradeInput: {
        fontSize: 25,
        width: 100,
        textAlign: 'right',
    },
    borderRight: {
        borderRight: "2px solid #1E2024",
        paddingRight: theme.spacing(4),
    },
    textGray: {
        color: theme.palette.lightestGray.main,
    }
}));

const addReportSheet = (reportId) => ({
    id: null,
    report_id: reportId,
    original_steel_id: '',
    replacement_steel_id: '',
    length: '',
    width: '',
    quantity: 1,
    original_price_cwt: 0,
    replacement_price_cwt: 0,
})

const updateSheet = (value, key, sheetId, reportSheets) => {
    reportSheets[sheetId][key] = value;
    return reportSheets;
};

const validateRange = (value, key, sheetId, reportSheets) => {
    if (key === 'width') {
        if (parseInt(value) < 53) {
            value = 53;
        }

        if (parseInt(value) > 130) {
            value = 130;
        }
    }

    if (key === 'length') {
        if (parseInt(value) < 118) {
            value = 118;
        }

        if (parseInt(value) > 576) {
            value = 576;
        }
    }
    reportSheets[sheetId][key] = value;
    return reportSheets;
};

const removeSheet = (sheetId, reportSheets) => {
    reportSheets[sheetId].to_delete = true;
    return reportSheets;
};

const NumberFormatCurrency = (props) => {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                    name: props.name,
                    value: values.value,
                    },
                });
            }}
            thousandSeparator
            isNumericString
            prefix="$"
        />
    );
}

const CalculateSheetWeight = (width, length, thickness) => {
    let $i = length*width*thickness;
    let $density = 25.4 ** 3 * 7.85 * 2.2046 / 1000000;
    return $i * $density;
}

let saveDebouncer;

const Calculator = () => {
    const classes = useStyles();

    const { reportId } = useParams();
    const location = useLocation();
    const history = useHistory();

    const [report, setReport] = useState(location.state != undefined ? location.state.report ?? {} : {});
    const [reportSheets, setReportSheets] = useState([]);
    const [savingChanges, setSavingChanges] = useState(false);
    const [sharingReport, setSharingReport] = useState(false);

    const [reportOptions, setReportOptions] = useState({});

    const [upgradeStats, setUpgradeStats] = useState({
        sheets: {},
    });

    const exportReport = () => {};

    const shareReport = async (values) => {
        const url = apiUrl+"/reports/"+report.id+"/share";
        const response = await fetch(url, {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: JSON.stringify(values) // body data type must match "Content-Type" header
        });

        const result = await response.json();
        console.log(result);
        // console.log(values);
        // alert('The report has been shared.');
    };

    useEffect(() => {
        let ignore = false;

        async function fetchData() {
            const url = apiUrl+"/reports-options/";
            const response = await fetch(url);

            const result = await response.json();

            if (!ignore) setReportOptions(result.data);
        }

        fetchData();
        return () => { ignore = true; }
    }, []);

    useEffect(() => {
        let ignore = false;

        async function fetchReport(reportId) {
            const url = apiUrl+"/reports/?filter={\"uuid\": \""+reportId+"\"}";
            const response = await fetch(url);

            const result = await response.json();

            if ('data' in result && result.data.length >= 1) {
                const report = result.data[0];
                if (report.abrasive_id === null) {
                    report.abrasive_id = '';
                }
                if (report.vol_per_hour === null) {
                    report.vol_per_hour = '';
                }
                if (report.service_life === null) {
                    report.service_life = '';
                }

                if('sheets' in report) {
                    if (report.sheets.length > 0) {
                        setReportSheets(report.sheets);
                    }

                    delete report.sheets;
                }

                setReport(report);
            } else {
                alert("The calculation you are trying to load could not be found. Please start a new calculation.");
                ignore = true;
                history.replace('/');
            }
        }

        if (!ignore) fetchReport(reportId);
        return () => { ignore = true; }
    }, [reportId]);

    useEffect(() => {
        if (savingChanges) {
            return;
        }

        async function saveReport() {
            console.log("Saving Changes: true");
            setSavingChanges(true);

            const url = apiUrl+"/reports/"+report.id;

            let data = {...report};
            data.sheets = [];
            data.to_delete = [];

            let incompleteSheets = [];

            reportSheets.map((sheet) => {
                if (sheet.to_delete) {
                    data.to_delete.push(sheet.id);
                    return;
                }

                if (sheet.report_id !== '' &&
                    sheet.original_steel_id !== '' &&
                    sheet.replacement_steel_id !== '' &&
                    sheet['length'] !== '' &&
                    sheet.width !== '' &&
                    sheet.quantity > 0 &&
                    sheet.original_price_cwt !== '' &&
                    sheet.replacement_price_cwt !== ''
                ) {
                    data.sheets.push(sheet);
                } else {
                    incompleteSheets.push(sheet);
                }
            });

            const response = await fetch(url, {
                method: 'PUT',
                mode: 'cors',
                cache: 'no-cache',
                headers: {
                  'Content-Type': 'application/json',
                  'Access-Control-Allow-Origin': '*'
                },
                redirect: 'follow', // manual, *follow, error
                referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
                body: JSON.stringify(data) // body data type must match "Content-Type" header
            });

            const result = await response.json();

            if (result) {
                if (result.abrasive_id === null) {
                    result.abrasive_id = '';
                }
                if (report.vol_per_hour === null) {
                    report.vol_per_hour = '';
                }
                if (report.service_life === null) {
                    report.service_life = '';
                }

                if('sheets' in result) {
                    if (result.sheets.length > 0) {

                        setReportSheets([...result.sheets, ...incompleteSheets]);
                    }
                    delete result.sheets;
                }

                setReport(result);
            }

            setTimeout(() => {console.log("Saving Changes: false"); setSavingChanges(false);}, 100);
        }

        if (saveDebouncer !== undefined) {
            clearTimeout(saveDebouncer);
        }

        saveDebouncer = setTimeout(saveReport, 5000);
    }, [report, reportSheets]);

    const setUpgradeSheet = (value, key, sheetId, reportSheets) => {
        if(value === "") return reportSheets;

        const originalSteel = reportOptions.steels[value];

        if (report.report_type === 'wear') {
            reportSheets[sheetId]['replacement_steel_id'] = originalSteel.wear_replacement_id;
        } else {
            reportSheets[sheetId]['replacement_steel_id'] = originalSteel.thickness_replacement_id;
        }

        return updateSheet(value, key, sheetId, reportSheets);
    };

    const swapReportType = async () => {
        setSavingChanges(true);

        const reportType = report.report_type === 'wear' ? 'thickness' : 'wear';
        const email = report.created_by;

        const url = `${apiUrl}/reports/`;
        let data = {
            report_type: reportType,
            created_by: email,
        };

        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'
            },
            body: JSON.stringify(data)
        });

        const jsonResponse = await response.json();

        if (jsonResponse.uuid) {
            const location = {
                pathname: '/calculator/'+jsonResponse.uuid,
                state: { report: jsonResponse }
            }

            history.push(location)
        } else {
            alert("There was an unknown error. Please try again later.");
        }

        // reset calculations
        setUpgradeStats({
            ...upgradeStats,
            totalPrice: 0,
            weightDiff: 0,
            operatingCosts: 0,
        });

        setSavingChanges(false);
    };

    const validates = () => {
        if(!reportSheets.length) {
            return false;
        }

        for(const sheet of reportSheets) {
            if(sheet.length === '' || sheet.width === '' || sheet.quantity === '') {
                return false;
            }

            if(sheet.original_price_cwt === 0 || sheet.replacement_price_cwt === 0) {
                return false;
            }
        }

        // check that abrasive is filled out correctly
        if(report.report_type === 'wear') {
            if(report.abrasive_id === '' || report.vol_per_hour === '' || report.service_life === '') {
                return false;
            }
        }

        return true;
    };

    const calculate = () => {
        let totalPrice = 0;
        let weightDiff = 0;
        let operatingCosts = 0;
        let totalOriginalThickness = 0;
        let totalUpgradedThickness = 0;

        reportSheets.map((sheet, sheetId) => {
            if (sheet.original_steel_id !== '') {
                const originalSteel = reportOptions.steels[
                    sheet.original_steel_id
                ];

                const originalWeight = CalculateSheetWeight(sheet['length'], sheet.width, originalSteel.thickness);
                const originalCost = sheet.original_price_cwt * (originalWeight / 100);

                let upgradedSteel;

                if (sheet.replacement_steel_id === '') {
                    if (report.report_type === 'wear') {
                        upgradedSteel = reportOptions.steels[
                            originalSteel.wear_replacement_id
                        ];
                    } else {
                        upgradedSteel = reportOptions.steels[
                            originalSteel.thickness_replacement_id
                        ];
                    }
                } else {
                    upgradedSteel = reportOptions.steels[sheet.replacement_steel_id];
                }

                if (upgradedSteel === undefined) {
                    upgradedSteel = originalSteel;
                }

                setReportSheets(updateSheet(upgradedSteel.id, 'replacement_steel_id', sheetId, [...reportSheets]));

                /* if (sheet.replacement_price_cwt === '') {
                    setReportSheets(updateSheet(upgradedSteel.price_cwt, 'replacement_price_cwt', sheetId, [...reportSheets]));
                } */

                const upgradedWeight = CalculateSheetWeight(sheet['length'], sheet.width, upgradedSteel.thickness);
                const upgradedCost = sheet.replacement_price_cwt * (upgradedWeight / 100);

                let abrasive;

                let originalWear;
                let upgradedWear;

                if (report.abrasive_id !== '') {
                    abrasive = reportOptions.abrasives[
                        report.abrasive_id
                    ];

                    originalWear = originalCost / (report.vol_per_hour * report.service_life);
                    upgradedWear = upgradedCost / (report.vol_per_hour * report.service_life * abrasive.wear_rate);
                }

                const upgradeSheet = {
                    original: {
                        weight: originalWeight,
                        cost: originalCost,
                        wear: originalWear,
                    },
                    upgraded: {
                        weight: upgradedWeight,
                        cost: upgradedCost,
                        wear: upgradedWear,
                    },
                    quantity: sheet.quantity,
                }

                let toUpdate = {...upgradeStats};
                toUpdate.sheets[sheetId] = upgradeSheet;

                setUpgradeStats(toUpdate);

                totalPrice += ((upgradedCost * sheet.quantity) - (originalCost * sheet.quantity));

                // weightDiff += ((originalWeight / upgradedWeight) - 1 ) * 100;
                weightDiff += ((originalWeight - upgradedWeight) / originalWeight ) * 100;
                totalOriginalThickness += originalSteel.thickness;
                totalUpgradedThickness += upgradedSteel.thickness;

                operatingCosts += originalWear > 0 && upgradedWear > 0 ?((originalWear - upgradedWear) / originalWear) * 100 : 0;
            }
        });

        setUpgradeStats({
            ...upgradeStats,
            totalPrice: totalPrice,
            weightDiff: weightDiff / Object.values(upgradeStats.sheets).length,
            totalOriginalThickness: totalOriginalThickness,
            totalUpgradedThickness: totalUpgradedThickness,
            operatingCosts: operatingCosts / Object.values(upgradeStats.sheets).length,
        });
    }

    if (!('uuid' in report)) {
        return (
            <Box mb={4}>
                <Typography>Loading...</Typography>
            </Box>
        )
    }

    return (
        <Box className={classes.container}>
            <Box className={classes.header}>
                <WPLogo className={classes.wpLogo} />

                <div style={{display: 'flex', flexDirection: 'column'}}>
                    <Button variant="contained" color="primary" size="small" spacing={4} component={Link} to="/" style={{marginBottom: '8px'}}>
                        <ChevronLeftIcon /> New Calculation
                    </Button>

                    {report.report_type === 'wear' && <>
                        <Button variant="contained" color="primary" size="small" spacing={4} disabled={savingChanges} onClick={() => {swapReportType()}}>
                            Reduce Weight
                        </Button>
                    </>}
                    {report.report_type !== 'wear' && <>
                        <Button variant="contained" color="primary" size="small" spacing={4} disabled={savingChanges} onClick={() => {swapReportType()}}>
                            Extend Service Life
                        </Button>
                    </>}
                </div>
            </Box>
            <Box className={classes.whyUpgradeHeader}>
                <img src={whyUpgrade} alt="Why Upgrade to Hardox® 500 Tuf?" />
            </Box>

            <Box className={classes.instructions}>
                {report.report_type === 'wear' && <>
                    <Typography variant="h3" component="h3" align="center" className={classes.padderBSm}>Extend Service Life: Wear Life Comparison</Typography>
                    <Typography>Click “Add Sheet” to compare currently used grades and thicknesses. Select the values for each category.<br/>Add more sheets, if needed. Select wear life criteria and click calculate to see results.</Typography>
                </>}
                {report.report_type !== 'wear' && <>
                    <Typography variant="h3" component="h3" align="center" className={classes.padderBSm}>Reduce Weight: Comparison</Typography>
                    <Typography>Click “Add Sheet” to compare currently used grades and thicknesses. Select the values for each category.<br/>Add more sheets, if needed. Click calculate to see results.</Typography>
                </>}
            </Box>

            <TableContainer className={classes.tableContainer}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Current Steel?</TableCell>
                            {/* <TableCell>What is your cost?</TableCell> */}
                            <TableCell>Upgraded Steel?</TableCell>
                            {/* <TableCell>What would your cost be?</TableCell> */}
                            <TableCell>Sheet Size?</TableCell>
                            {/* <TableCell>Sheet Length?</TableCell>
                            <TableCell>Sheet Width?</TableCell> */}
                            <TableCell>Sheets per Month?</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {reportSheets.map((sheet, sheetId) => {
                            if (sheet.to_delete) {
                                return;
                            }

                            return (
                                <TableRow key={sheetId}>
                                    <TableCell>
                                        {'steels' in reportOptions &&
                                            <TextField
                                                disabled={savingChanges}
                                                required={true}
                                                label="Steel Type"
                                                variant="outlined"
                                                select
                                                fullWidth={true}
                                                margin="normal"
                                                name="original-steel-select"
                                                value={sheet.original_steel_id}
                                                onChange={(event) => (setReportSheets(setUpgradeSheet(event.target.value, 'original_steel_id', sheetId, [...reportSheets])))}
                                            >
                                                <MenuItem value="">Select a Steel Type</MenuItem>
                                                {Object.values(reportOptions.steels).map((steel) => {
                                                    if (steel.type.includes('500 Tuf')) {
                                                        return;
                                                    }

                                                    return (
                                                        <MenuItem key={steel.id} value={steel.id}>{steel.type}</MenuItem>
                                                    )
                                                })}
                                            </TextField>
                                        }
                                        <TextField
                                            disabled={savingChanges}
                                            required={true}
                                            fullWidth={true}
                                            variant="outlined"
                                            label="Cost/CWT"
                                            value={sheet.original_price_cwt}
                                            onChange={(event) => (setReportSheets(updateSheet(event.target.value, 'original_price_cwt', sheetId, [...reportSheets])))}
                                            name="original-price-cwt"
                                            InputProps={{
                                                inputComponent: NumberFormatCurrency,
                                            }}
                                            margin="normal"
                                        />
                                    </TableCell>
                                    {/* <TableCell>
                                        <TextField
                                            required={true}
                                            fullWidth={true}
                                            variant="outlined"
                                            label=""
                                            value={sheet.original_price_cwt}
                                            onChange={(event) => (setReportSheets(updateSheet(event.target.value, 'original_price_cwt', sheetId, [...reportSheets])))}
                                            name="original-price-cwt"
                                            InputProps={{
                                                inputComponent: NumberFormatCurrency,
                                            }}
                                        />
                                    </TableCell> */}
                                    <TableCell>
                                        {'steels' in reportOptions &&
                                            <TextField
                                                disabled={savingChanges}
                                                required={true}
                                                label="Steel Type"
                                                variant="outlined"
                                                select
                                                fullWidth={true}
                                                margin="normal"
                                                name="replacement-steel-select"
                                                value={sheet.replacement_steel_id}
                                                onChange={(event) => (setReportSheets(setUpgradeSheet(event.target.value, 'replacement_steel_id', sheetId, [...reportSheets])))}
                                                >
                                                <MenuItem value="">Select a Steel Type</MenuItem>
                                                {Object.values(reportOptions.steels).map((steel) => {
                                                    if (!steel.type.includes('500 Tuf')) {
                                                        return;
                                                    }

                                                    return (
                                                        <MenuItem key={steel.id} value={steel.id}>{steel.type}</MenuItem>
                                                    )
                                                })}
                                            </TextField>
                                        }
                                        <TextField
                                            disabled={savingChanges}
                                            required={true}
                                            fullWidth={true}
                                            variant="outlined"
                                            label="Cost/CWT"
                                            value={sheet.replacement_price_cwt}
                                            onChange={(event) => (setReportSheets(updateSheet(event.target.value, 'replacement_price_cwt', sheetId, [...reportSheets])))}
                                            name="replacement-price-cwt"
                                            InputProps={{
                                                inputComponent: NumberFormatCurrency,
                                            }}
                                            margin="normal"
                                        />
                                    </TableCell>
                                    {/* <TableCell>
                                        <TextField
                                            required={true}
                                            fullWidth={true}
                                            variant="outlined"
                                            label=""
                                            value={sheet.replacement_price_cwt}
                                            onChange={(event) => (setReportSheets(updateSheet(event.target.value, 'replacement_price_cwt', sheetId, [...reportSheets])))}
                                            name="replacement-price-cwt"
                                            InputProps={{
                                                inputComponent: NumberFormatCurrency,
                                            }}
                                        />
                                    </TableCell> */}
                                    <TableCell>
                                        <TextField
                                            disabled={savingChanges}
                                            required={true}
                                            fullWidth={true}
                                            key={sheetId+"-width"}
                                            variant="outlined"
                                            name="width"
                                            label='Width (53" - 130")'
                                            InputLabelProps={{ shrink: true }}
                                            value={sheet.width}
                                            type="number"
                                            InputProps={{ inputProps: { min: 53, max: 130 } }}
                                            onChange={(event) => (setReportSheets(updateSheet(event.target.value, 'width', sheetId, [...reportSheets])))}
                                            onBlur={(event) => (setReportSheets(validateRange(event.target.value, 'width', sheetId, [...reportSheets])))}
                                            margin="normal"
                                        />
                                        <TextField
                                            disabled={savingChanges}
                                            required={true}
                                            fullWidth={true}
                                            key={sheetId+"-length"}
                                            variant="outlined"
                                            name="length"
                                            label='Length (118" - 576")'
                                            InputLabelProps={{ shrink: true }}
                                            value={sheet['length']}
                                            type="number"
                                            InputProps={{ inputProps: { min: 118, max: 576 } }}
                                            onChange={(event) => (setReportSheets(updateSheet(event.target.value, 'length', sheetId, [...reportSheets])))}
                                            onBlur={(event) => (setReportSheets(validateRange(event.target.value, 'length', sheetId, [...reportSheets])))}
                                            margin="normal"
                                        />
                                    </TableCell>
                                    {/* <TableCell>
                                        <TextField
                                            required={true}
                                            fullWidth={true}
                                            key={sheetId+"-length"}
                                            variant="outlined"
                                            name="length"
                                            label=""
                                            value={sheet['length']}
                                            type="number"
                                            inputProps={{ min: 118, max: 576 }}
                                            onChange={(event) => (setReportSheets(updateSheet(event.target.value, 'length', sheetId, [...reportSheets])))}
                                        />
                                    </TableCell> */}
                                    <TableCell>
                                        <TextField
                                            disabled={savingChanges}
                                            required={true}
                                            fullWidth={true}
                                            key={sheetId+"-quantity"}
                                            variant="outlined"
                                            name="quantity"
                                            label=""
                                            type="number"
                                            value={sheet.quantity}
                                            onChange={(event) => (setReportSheets(updateSheet(event.target.value, 'quantity', sheetId, [...reportSheets])))}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <Button variant="contained" color="primary" size="small" spacing={4} onClick={() => setReportSheets(removeSheet(sheetId, [...reportSheets]))}>
                                            <DeleteIcon />
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>

            <Box marginBottom={4}>
                <Button variant="contained" color="secondary" disabled={!('steels' in reportOptions)} onClick={() => setReportSheets([...reportSheets, addReportSheet(report.id)])}>Add Sheet</Button>
            </Box>

            {report.report_type === 'wear' &&
            <TableContainer className={classes.tableContainer}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>What kind of material are you working with?</TableCell>
                            <TableCell>Current Volume/Hour (ton)</TableCell>
                            <TableCell>Current Service Life (hours)</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>
                                {'abrasives' in reportOptions &&
                                report.report_type === 'wear' &&
                                <FormControl fullWidth={true}>
                                    <Select
                                        disabled={savingChanges}
                                        required={true}
                                        labelId="abrasive-select-label"
                                        placeholder="Choose an abrasive"
                                        variant="outlined"
                                        id="abrasive-select"
                                        displayEmpty
                                        value={report.abrasive_id}
                                        onChange={(event) => (setReport({...report, abrasive_id: event.target.value}))}
                                    >
                                        <MenuItem value=''>Select Abrasive</MenuItem>
                                        {Object.values(reportOptions.abrasives).map((abrasive) => (
                                            <MenuItem key={abrasive.id} value={abrasive.id}>{abrasive.name}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                }
                            </TableCell>
                            <TableCell>
                                <TextField
                                    disabled={savingChanges}
                                    required={true}
                                    fullWidth={true}
                                    variant="outlined"
                                    name="volume"
                                    label=""
                                    type="number"
                                    value={report.vol_per_hour}
                                    onChange={(event) => (setReport({...report, vol_per_hour: event.target.value}))}
                                />
                            </TableCell>
                            <TableCell>
                                <TextField
                                    disabled={savingChanges}
                                    required={true}
                                    fullWidth={true}
                                    variant="outlined"
                                    name="service_life"
                                    label=""
                                    type="number"
                                    value={report.service_life}
                                    onChange={(event) => (setReport({...report, service_life: event.target.value}))}
                                />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            }

            <Box m={2}>
                <Button variant="contained" color="primary" size="large" spacing={4} disabled={!validates()} onClick={() => calculate()}>CALCULATE</Button>
            </Box>

            {'totalPrice' in upgradeStats &&
            <Paper elevation={8} className={classes.paperContainer}>
                <Box mb={4}>
                    <Typography className={classes.upgradeTextLarge}>We can improve your bottom line:</Typography>
                </Box>

                {/* {Object.keys(upgradeStats.sheets).map((sheetId) => {
                    return (
                        <UpgradeRow key={sheetId} sheet={upgradeStats.sheets[sheetId]} sheetId={sheetId} />
                    )
                })} */}
                <Box mb={4} width="100%">
                    <Grid container spacing={4} direction="row" alignItems='center'>
                        <Grid xs={4} item align='right'>
                            <Typography className={classes.upgradeTextLarge}>
                                <NumberFormat
                                    value={upgradeStats.totalPrice}
                                    prefix="$"
                                    thousandSeparator
                                    displayType="text"
                                    allowNegative={false}
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                />
                                {upgradeStats.totalPrice > 0 &&
                                    <ArrowUpwardIcon />
                                }
                                {upgradeStats.totalPrice < 0 &&
                                    <ArrowDownwardIcon />
                                }
                            </Typography>
                            <Typography className={[classes.upgradeTextDescription, classes.textGray]}>
                                Total Cost Difference
                            </Typography>
                        </Grid>
                        <Grid xs={4} item align='center'>
                            <Typography className={classes.upgradeTextLarge}>
                                {Math.round(upgradeStats.weightDiff)}%
                                {Math.round(upgradeStats.weightDiff) > 0 &&
                                    <ArrowDownwardIcon />
                                }
                                {Math.round(upgradeStats.weightDiff) < 0 &&
                                    <ArrowUpwardIcon />
                                }
                            </Typography>
                            <Typography className={[classes.upgradeTextDescription, classes.textGray]}>
                                Savings in Steel Weight
                            </Typography>
                        </Grid>
                        {report.abrasive_id != '' &&
                            <Grid xs={4} item>
                                <Typography className={classes.upgradeTextLarge}>
                                    {
                                        Math.round(
                                            (
                                                (
                                                    (
                                                        Math.round(
                                                            (upgradeStats.totalUpgradedThickness / upgradeStats.totalOriginalThickness) * 100
                                                        ) / 100
                                                    ) * reportOptions.abrasives[report.abrasive_id].wear_rate
                                                ) - 1
                                            ) * 100 * 100
                                        ) / 100
                                    }%
                                    {upgradeStats.operatingCosts != 0 && Math.round(upgradeStats.operatingCosts) > 0 && (<ArrowUpwardIcon />)}
                                    {upgradeStats.operatingCosts != 0 && Math.round(upgradeStats.operatingCosts) < 0 && (<ArrowDownwardIcon />)}
                                    {upgradeStats.operatingCosts == 0 && <ArrowUpwardIcon />}
                                </Typography>
                                <Typography className={[classes.upgradeTextDescription, classes.textGray]}>
                                    Increased Service Life
                                </Typography>
                            </Grid>
                        }
                    </Grid>
                </Box>
                <Box marginBottom={2} width="100%">
                    <Grid container spacing={4} alignItems='center' width="100%" marginBottom={4}>
                        {/*
                        <Grid xs={6} item align='right'>
                            <Button variant="contained" color="primary" size="large" spacing={4} onClick={() => exportReport()}>EXPORT</Button>
                        </Grid>
                        */}
                        <Grid xs={6} item>
                            <Button variant="contained" color="primary" size="large" spacing={4} onClick={() => setSharingReport(!sharingReport)}>SHARE</Button>
                        </Grid>
                    </Grid>
                </Box>
                <Box width="100%">
                    <Typography align="center">
                        *Prices and weight are estimated, not the final total.
                    </Typography>
                </Box>
                {sharingReport === true && (
                    <Box marginTop={2} width="100%">
                        <Form
                            onSubmit={(values) => (shareReport(values))}
                            render={({ handleSubmit, form, submitting, pristine, values }) => (
                                <form onSubmit={handleSubmit}>
                                    <div>
                                        <Grid container spacing={4} justifyContent='center' alignItems='center' width="100%" marginBottom={4}>
                                            <Grid item>
                                                <RffTextField label="Email" name="email" variant="outlined" required />
                                            </Grid>

                                            <Grid item>
                                                <ButtonGroup variant="contained" color="primary" size="large" disabled={submitting || pristine}>
                                                    <Button onClick={() => {form.submit()}}>SEND</Button>
                                                </ButtonGroup>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </form>
                            )}
                        />
                    </Box>
                )}
            </Paper>
            }
        </Box>
    );
}

export default Calculator;
